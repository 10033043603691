<template>
  <section>
    <div class="box p-4 mt-2">
      <div class="flex justify-between items-center">
        <div class="flex gap-2 items-center">
          <i class="pi pi-copy text-xl"></i>
          <p class="font-bold text-xl">Contratos</p>
        </div>
        <Button label="Crear contrato" @click="$router.push({ name: 'pharmasan.ventas.contrato-precio.crear' })" severity="success" icon="pi pi-plus" />
      </div>
      <div class="w-full mt-4">
        <DataTable class="p-datatable-sm" :value="contratosStore._paginado_contratos.rows" tableStyle="min-width: 50rem">
          <Column field="NumCto" header="No. Contrato"></Column>
          <Column field="Cliente.CardName" header="Cliente"></Column>
          <Column :style="{ width: '15rem' }" field="Descript" header="Descripción"></Column>
          <Column field="ValorTotal" header="Valor total"></Column>
          <Column header="Estado">
            <template #body="slotProps">
              <div class="font-bold flex gap-2 items-center" :class="slotProps.data.Status == 1 ? 'text-green-600' : slotProps.data.Status == 0 ? 'text-red-600' : 'text-gray-600'">
                <div class="w-2 h-2 rounded-full" :class="slotProps.data.Status == 1 ? 'bg-green-600' : slotProps.data.Status == 0 ? 'bg-red-600': 'bg-gray-600'"></div>
                <p>{{ slotProps.data.Status == 0 ? 'Inactivo' : slotProps.data.Status == 1 ? 'Activo' : 'Borrador' }}</p>
              </div>
            </template>
          </Column>
          <Column :style="{ width: '5rem' }" headerClass="text-center" header="Acciones">
            <template #body="slotProps">
              <div class="flex w-full justify-around">
                <button v-tooltip="'Ver contrato'"><i class="pi pi-eye" @click="$router.push({ name: 'pharmasan.ventas.contrato-precio.ver', params: { id: slotProps.data.Id } })"></i></button>
                <button v-tooltip="'Editar contrato'"><i class="pi pi-pencil" @click="$router.push({ name: 'pharmasan.ventas.contrato-precio.editar', params: { id: slotProps.data.Id } })"></i></button>
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator :rows="limit" @page="onPageChanged" :totalRecords="contratosStore._paginado_contratos.count" :rowsPerPageOptions="[10, 20, 30]"></Paginator>
      </div>
    </div>
    <router-view></router-view>
  </section>
</template>
<script>
  import { onMounted, ref, computed } from 'vue'
  import { useContratosStore } from '../stores/contratos.store'
  export default {
    name: 'configuracionContratos',
    setup () {
      const contratosStore = useContratosStore()
      const limit = ref(10)
      const offset = ref(0)
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value
        }
      })
      const onPageChanged = ({ rows, first }) => {
        limit.value = rows
        offset.value = first
        contratosStore.paginate(params.value)
      }
      const listadoContratos = ref([])
      onMounted(() => {
        contratosStore.paginate(params.value)
      })
      return {
        listadoContratos,
        params,
        limit,
        offset,
        contratosStore,
        onPageChanged
      }
    }
  }
</script>
<style scoped>
</style>
